"use client";

import { createContext } from "react";
import { useMemo } from "react";

import useManyOrganizations from "~/requests/queries/manyOrganizations";
import { Organizations } from "~/services/organizations/many";

export type Context = {
  isFetching: boolean;
  organizations?: Organizations;
};

export const OrganizationsContext = createContext<Context>({
  isFetching: false,
  organizations: undefined,
});

type ProviderProps = {
  children: React.ReactNode;
  id?: null | string;
};

export function OrganizationsProvider({ children }: ProviderProps) {
  const { data: organizations, isFetching } = useManyOrganizations({
    index: 1,
    size: 100,
  });

  const contextValue = useMemo(
    () => ({
      isFetching,
      organizations: organizations,
    }),
    [organizations, isFetching],
  );

  return (
    <OrganizationsContext.Provider value={contextValue}>
      {children}
    </OrganizationsContext.Provider>
  );
}
