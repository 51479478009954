import { useQuery } from "@tanstack/react-query";

import { Organizations } from "~/services/organizations/many";
import { TablePagination } from "~/shared/Table";
import { TableInput } from "~/types/types";

export const ORGANIZATIONS_QUERY_KEY = "organizations";

const useManyOrganizations = ({ index, size }: TablePagination) => {
  return useQuery<TableInput, unknown, Organizations>({
    keepPreviousData: true,
    queryFn: async () =>
      (await fetch(`/api/organizations?index=${index}&size=${size}`)).json(),
    queryKey: [ORGANIZATIONS_QUERY_KEY, { index, size }],
  });
};

export default useManyOrganizations;
