import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import { SetModeResponse } from "~/services/users/setMode";
import MainToast from "~/shared/Toasts/MainToast";

const useSetMode = () => {
  const queryClient = useQueryClient();
  return useMutation<SetModeResponse, unknown, { liveMode: boolean }>({
    mutationFn: async (variables) => {
      return await fetch("/api/users/setMode", {
        body: JSON.stringify(variables),
        method: "PATCH",
      }).then((res) => res.json());
    },
    mutationKey: ["setMode"],
    onError: (error) => {
      console.error("Set Mode Error", error);
      toast.custom((to) =>
        MainToast({
          t: to,
          title: "Something went wrong :(",
          toast,
          type: "error",
        }),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
    },
  });
};

export default useSetMode;
