import { Permission } from "@everfund/types";

import { useUser } from "~/context/user";
import { useWorkspace } from "~/context/workspace";

export function useHasPermission() {
  const { user } = useUser();
  const { workspace } = useWorkspace();

  const hasPermission = (permission: Permission) => {
    if (!user || !workspace) {
      return false;
    }

    if (!workspace.useAccessControl) {
      return true;
    }

    return user.permissions?.some?.includes(permission);
  };

  const hasOrganizationPermission = (
    permission: Permission,
    organizationId: string | undefined,
    groupId?: string | undefined,
  ) => {
    if (!user || !workspace) {
      return false;
    }

    if (!workspace.useAccessControl) {
      return true;
    }

    if (groupId) {
      return (
        user?.permissions?.organizations[organizationId]?.groups[
          groupId
        ]?.includes(permission) ||
        user?.permissions?.organizations[organizationId]?.orgLevel.includes(
          permission,
        )
      );
    }

    if (organizationId) {
      return user?.permissions?.organizations[
        organizationId
      ]?.orgLevel.includes(permission);
    }

    return false;
  };

  return {
    hasOrganizationPermission,
    hasPermission,
  };
}
