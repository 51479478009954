import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faVial } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";
import { Tooltip } from "react-tooltip";

import { useUser } from "~/context/user";
import useSetMode from "~/requests/mutations/setMode";

import Button from "../Button";

const TestModeBarrier = () => {
  const { user } = useUser();

  const { mutate: switchLiveMode } = useSetMode();
  const t = useTranslations("components.TestModeBarrier");

  const liveMode = user?.liveMode;

  const toggleLiveMode = async () => {
    switchLiveMode({
      liveMode: !liveMode,
    });
  };

  return (
    <div className="text-center">
      <FontAwesomeIcon
        className="mx-auto !h-16 !w-auto text-gray-400"
        icon={faVial}
      />

      <h3 className="mt-2 text-xl font-medium text-primary">
        {t("didYouMean", { mode: liveMode ? t("live") : t("test") })}
      </h3>
      <p className="mt-1 text-base text-secondary">
        {t("weCouldNotFind", {
          alternativeMode: !liveMode ? t("live") : t("test"),
          mode: liveMode ? t("live") : t("test"),
        })}
      </p>
      <div className="mt-6">
        <Tooltip
          aria-haspopup="true"
          className="z-50"
          id="testmode"
          place="bottom"
        />
        <Button
          buttonColor="blue"
          leftIcon={
            <FontAwesomeIcon
              className="-ml-1 mr-2 !h-4 !w-auto"
              icon={faMagnifyingGlass}
            />
          }
          onClick={() => toggleLiveMode()}
        >
          {t("switchTo", { mode: !liveMode ? t("live") : t("test") })}
        </Button>
      </div>
    </div>
  );
};

export default TestModeBarrier;
