"use client";

import { useContext } from "react";

import { Context, OrganizationsContext } from "./provider";

export function useOrganizations(): Context {
  const context = useContext(OrganizationsContext);

  if (!context) {
    return { isFetching: false, organizations: undefined };
  }

  return context;
}
