"use client";

import { useAnalytics } from "@everfund/event-detective";
import { createContext } from "react";
import { useEffect, useMemo } from "react";

import useSingleWorkspace from "~/requests/queries/singleWorkspace";
import { Workspace } from "~/services/workspaces/single";

export type Context = {
  isFetching: boolean;
  workspace?: Workspace;
};

export const WorkspaceContext = createContext<Context>({
  isFetching: false,
  workspace: undefined,
});

type ProviderProps = {
  children: React.ReactNode;
  id?: null | string;
};

export function WorkspaceProvider({ children }: ProviderProps) {
  const { group } = useAnalytics();
  const { data: workspace, isFetching } = useSingleWorkspace();

  const contextValue = useMemo(
    () => ({
      isFetching,
      workspace: workspace,
    }),
    [workspace, isFetching],
  );

  useEffect(() => {
    if (workspace?.id) {
      group({
        groupId: workspace.id,
        traits: {
          name: workspace.name,
          plan: workspace.type,
        },
      });
    }
  }, [group, workspace]);

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  );
}
