"use client";

import { useAuth } from "@clerk/nextjs";
import { faLocationPinSlash } from "@fortawesome/pro-thin-svg-icons";
import { useTranslations } from "next-intl";

import useCrisp from "~/hooks/useCrisp";
import Footer from "~/layouts/shared/Footer";
import Nav from "~/layouts/shared/Nav";
import UnauthenticatedLayout from "~/layouts/Unauthenticated";
import { EmptyBarrier } from "~/shared/Barriers";
import Button from "~/shared/Button";
import Card from "~/shared/Card";
import routes from "~/utils/routes";

export function NotFoundComponent() {
  const Crisp = useCrisp();
  const t = useTranslations("NotFoundPage");
  return (
    <main className="flex flex-1 flex-col items-center justify-center">
      <Card cardClassName="px-12" hideDivider>
        <EmptyBarrier
          description={
            <span className="flex flex-col space-y-6">
              <span>{t("notFound")}</span>
              <div className="space-x-4">
                <Button buttonColor="blue" to={routes.home}>
                  {t("goHome")}
                </Button>
                <button onClick={() => Crisp?.chat.open()}>
                  {t("talkToSupport")} <span aria-hidden="true">&rarr;</span>
                </button>
              </div>
            </span>
          }
          icon={faLocationPinSlash}
          title="This page does not exist"
        />
      </Card>
    </main>
  );
}

export default function NotFound() {
  const { isSignedIn } = useAuth();

  return (
    <>
      {isSignedIn ? (
        <div className="flex min-h-screen flex-col">
          <Nav />
          <div className="flex min-h-screen flex-col justify-between pt-16 md:pl-72 md:pt-0">
            <NotFoundComponent />
            <Footer />
          </div>
        </div>
      ) : (
        <UnauthenticatedLayout>
          <NotFoundComponent />
        </UnauthenticatedLayout>
      )}
    </>
  );
}
