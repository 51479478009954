import { faEarthEurope } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "next-intl";

const CountryBarrier = () => {
  const t = useTranslations("components.CountryBarrier");
  return (
    <div className="text-center">
      <FontAwesomeIcon
        className="mx-auto !h-16 !w-auto text-gray-400"
        icon={faEarthEurope}
      />

      <h3 className="mt-2 text-xl font-medium text-primary">{t("message")}</h3>
    </div>
  );
};

export default CountryBarrier;
