import { cn } from "@everfund/ui";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type EmptyBarrierType = {
  className?: string;
  description?: JSX.Element | string;
  icon?: IconDefinition | JSX.Element;
  title?: string;
};

const EmptyBarrier = ({
  className,
  description,
  icon,
  title,
}: EmptyBarrierType) => {
  return (
    <div className={cn("space-y-4 px-5 py-6 text-center", className)}>
      {typeof icon === "object" && "iconName" in icon ? (
        <FontAwesomeIcon
          className="mx-auto !h-16 !w-auto text-gray-400"
          icon={icon}
        />
      ) : icon ? (
        icon
      ) : null}

      <h3 className="text-xl font-medium text-primary">{title}</h3>
      {description && (
        <p className="mx-auto max-w-3xl text-base text-secondary">
          {description}
        </p>
      )}
    </div>
  );
};

export default EmptyBarrier;
