import { AuthenticationFooter } from "./Footer";

interface UnauthenticatedLayoutProps {
  children: React.ReactNode;
}

const UnauthenticatedLayout = ({ children }: UnauthenticatedLayoutProps) => {
  return (
    <>
      <div className="flex min-h-screen flex-col justify-between pb-8">
        <div className="text-left font-sans text-white md:flex md:items-center md:justify-center md:pt-8">
          {children}
        </div>
        <AuthenticationFooter />
      </div>
    </>
  );
};

export default UnauthenticatedLayout;
