import { useTranslations } from "next-intl";

import { EVERFUND_MARKETING_DOMAIN } from "~/context/constants";

export const AuthenticationFooter = () => {
  const t = useTranslations("layouts.components.Footer");

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 px-8 text-center font-sans text-slate-700 md:flex-row md:justify-between">
      <span className="text-secondary">
        <a
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
          href={`https://${EVERFUND_MARKETING_DOMAIN}`}
        >
          {t("copywrite", { year: new Date().getFullYear() })}
        </a>
      </span>
      <span className="flex flex-row flex-wrap justify-center gap-2">
        <a
          className="text-right text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
          href={`https://www.iubenda.com/privacy-policy/82630393/cookie-policy`}
        >
          {t("cookiePolicy")}
        </a>
        <a
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
          href={`https://www.iubenda.com/privacy-policy/82630393/full-legal`}
        >
          {t("privacyPolicy")}
        </a>
        <a
          className="text-xs font-normal leading-4 transition-opacity hover:cursor-pointer hover:opacity-50"
          href={`https://www.iubenda.com/terms-and-conditions/82630393`}
        >
          {t("termsAndConditions")}
        </a>
      </span>
    </div>
  );
};
